<template>
  <the-loader v-if="showLoading"></the-loader>
  <router-view></router-view>
</template>

<script>
import { mapState } from 'vuex';
import { AUTO_LOGIN_ACTION } from './store/storeconstants';
import { defineAsyncComponent } from 'vue';

const TheLoader = defineAsyncComponent(() =>
    import(/* webpackChunkName: "Loader" */ './components/TheLoader.vue'),
);
export default {
  name: 'App',
  computed: {
    ...mapState({
      showLoading: (state) => state.showLoading,
      autoLogout: (state) => state.auth.autoLogout,
    }),
  },

  watch: {
    autoLogout(curValue, oldValue) {
      if (curValue && curValue != oldValue) {
        //  this.$router.replace('/login');
      }
    },
  },
  components: {
    TheLoader,
  },
  created() {
    this.$store.dispatch(`auth/${AUTO_LOGIN_ACTION}`);
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400&family=Tenor+Sans&display=swap');

html, body, div#app {
  margin: 0 !important;
  height: 100%;
}
.el-container {
  height: 100%;
}
.el-row {
  width: 100%;
}
.el-col {
  margin-bottom: 20px;
}
.form {
  min-width: 370px!important;
}
h2 {
  font-family: 'Tenor Sans', sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
}
h3 {
  font-family: 'Tenor Sans', sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  font-style: normal;
  border-bottom: 0;
  font-size: 15px;
}
.doc {
  margin-bottom: 0;
  border-bottom: 3px solid #5E597B;;
  width: fit-content;
}
input {
  font-family: 'Montserrat', sans-serif!important;
  font-weight: 300!important;
  font-style: normal!important;
  font-size: 16px!important;
}
.el-form-item .el-button {
  font-family: 'Tenor Sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  text-transform: uppercase;
}
.el-link {
  font-family: 'Montserrat', sans-serif!important;
  font-weight: 300!important;
  font-style: normal!important;
  font-size: 12px!important;
}
a {
  text-decoration: none;
  color: inherit;
}
.card-header {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
}
.el-footer {
  font-family: 'Montserrat', sans-serif!important;
  font-weight: 300!important;
  font-style: normal!important;
  font-size: 12px!important;
  margin-top: -80px;
  background-color: #F4F4F6!important;
  height: initial!important;
  line-height: 30px!important;
  padding: 10px 0!important;
}
.el-aside,
.burger {
  background-color: #5E597B!important;
  color: #ffffff!important;
}
.video {
  position:relative;
  padding-bottom:56.25%;
  padding-top:25px;
  height:0;
}
.video iframe {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}
.left_column,
.right_column {
  margin-bottom: initial;
}
</style>
