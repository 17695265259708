import { createRouter, createWebHistory } from 'vue-router';
import store from './store/store';
import { IS_USER_AUTHENTICATE_GETTER } from './store/storeconstants';

const Login = () =>
    import(/* webpackChunkName: "Login" */ './pages/Login.vue');
const Signup = () => import('./pages/Signup.vue');
const Courses = () => import('./pages/Courses.vue');
const Course = () => import('./pages/Course.vue');
const Classes = () => import('./pages/Classes.vue');
const Class = () => import('./pages/Class.vue');
const Lilas = () => import('./pages/Lilas.vue');
const Lila = () => import('./pages/Lila.vue');
const VideoCourse = () => import('./pages/VideoCourse.vue');
const VideoClass = () => import('./pages/VideoClass.vue');
const VideoLila = () => import('./pages/VideoLila.vue');
const Forgotpass = () => import('./pages/Forgotpass.vue');
const Resetpass = () => import('./pages/Resetpass.vue');
const MasterTraining = () => import('./pages/MasterTraining.vue');

const routes = [
   { path: '', component: Login, meta: { auth: false } },
   { path: '/login', component: Login, meta: { auth: false } },
   { path: '/signup', component: Signup, meta: { auth: false } },
   { path: '/forgot', component: Forgotpass, meta: { auth: false } },
   { path: '/reset/:token', component: Resetpass, meta: { auth: false } },
   { path: '/courses', component: Courses, meta: { auth: true } },
   { path: '/course/:id', component: Course, meta: { auth: true } },
   { path: '/lilas', component: Lilas, meta: { auth: true } },
   { path: '/lila/:id', component: Lila, meta: { auth: true } },
   { path: '/classes', component: Classes, meta: { auth: true } },
   { path: '/class/:id', component: Class, meta: { auth: true } },
   { path: '/course/video/:id', component: VideoCourse, meta: { auth: true } },
   { path: '/class/video/:id', component: VideoClass, meta: { auth: true } },
   { path: '/lila/video/:id', component: VideoLila, meta: { auth: true } },
   { path: '/masters', component: MasterTraining, meta: { auth: true } }
];

const router = createRouter({
   history: createWebHistory(),
   routes,
});

router.beforeEach((to, from, next) => {
   if ('auth' in to.meta && to.meta.auth && !store.getters[`auth/${IS_USER_AUTHENTICATE_GETTER}`]) {
      next('/login');
   } else if ('auth' in to.meta && !to.meta.auth && store.getters[`auth/${IS_USER_AUTHENTICATE_GETTER}`]) {
      next('/courses');
   } else {
      next();
   }
});

export default router;
