import Axios from 'axios';
// import SignupValidations from '../../../services/SignupValidations';
import {
   AUTH_ACTION,
   LOGIN_ACTION,
   FORGOT_ACTION,
   AUTO_LOGIN_ACTION,
   LOGOUT_ACTION,
   SET_USER_TOKEN_DATA_MUTATION,
   SIGNUP_ACTION,
   AUTO_LOGOUT_ACTION,
   SET_AUTO_LOGOUT_MUTATION,
} from '../../storeconstants';

let timer = '';
export default {
   [LOGOUT_ACTION](context) {
      context.commit(SET_USER_TOKEN_DATA_MUTATION, {
         email: null,
         token: null,
         expiresIn: null,
         refreshToken: null,
         userId: null,
      });
      localStorage.removeItem('userData');
      if (timer) {
         clearTimeout(timer);
      }
   },

   [AUTO_LOGOUT_ACTION](context) {
      // context.dispatch(LOGOUT_ACTION);
      context.commit(SET_AUTO_LOGOUT_MUTATION);
   },

   async [LOGIN_ACTION](context, payload) {
      return context.dispatch(AUTH_ACTION, {
         ...payload,
         url: `https://betterlife-school.ru/app/auth/login`,
      });
   },

   async [SIGNUP_ACTION](context, payload) {
      return context.dispatch(AUTH_ACTION, {
         ...payload,
         url: `https://betterlife-school.ru/app/auth/registration`,
      });
   },

   async [FORGOT_ACTION](context, payload) {
      return context.dispatch(AUTH_ACTION, {
         ...payload,
         url: `https://betterlife-school.ru/app/auth/forgot`,
      });
   },

   [AUTO_LOGIN_ACTION](context) {
      let userDataString = localStorage.getItem('userData');

      if (userDataString) {
         let userData = JSON.parse(userDataString);
         let expirationTime = userData.expiresIn - new Date().getTime();

         if (expirationTime < 10000) {
            context.dispatch(AUTO_LOGOUT_ACTION);
         } else {
            timer = setTimeout(() => {
               context.dispatch(AUTO_LOGOUT_ACTION);
            }, expirationTime);
         }

         context.commit(SET_USER_TOKEN_DATA_MUTATION, userData);
      }
   },

   async [AUTH_ACTION](context, payload) {
      let postData = {
         name: payload.name,
         email: payload.email,
         password: payload.password,
         returnSecureToken: true,
      };
      let response = '';
      try {
         response = await Axios.post(payload.url, postData);
      } catch (err) {
         // context.commit(LOADING_SPINNER_SHOW_MUTATION, false, {
         //     root: true,
         // });
         throw err.response.data.message;
      }

      if (response.status === 200) {
         // let expirationTime = +response.data.expiresIn * 1000;
         // let expirationTime = 3600 * 1000;

         // timer = setTimeout(() => {
         //    context.dispatch(AUTO_LOGOUT_ACTION);
         // }, expirationTime);

         // let tokenData = response.data.token;
         let tokenData = {
            // email: response.data.email,
            // token: response.data.idToken,
            token: response.data.token,
            // expiresIn: expirationTime,
            // refreshToken: response.data.refreshToken,
            // userId: response.data.localId,
         };
         localStorage.setItem('userData', JSON.stringify(tokenData));
         context.commit(SET_USER_TOKEN_DATA_MUTATION, tokenData);
      }
   },
};
